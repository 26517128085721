<!--  -->
<template>
  <div class="downModel">
    <div class="pcDown" @click="downLoad" :class="{ 'is-baidu': isBaidu, 'is-qihu': isQihu, 'is-qihu2': isQihu2 }"></div>
    <div class="phoneDown">
      <img :src="rwmImg" class="rwm" />
      <div class="scanCover">
        <span class="scan_cover"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { qrcode } from "../../api/active";
export default {
  name: "",
  components: {},
  data() {
    return {
      rwmImg: "",
    };
  },
  props: {
    channel: null,
  },
  computed: {
    isBaidu() {
      return this.channel == 20210426043;
    },
    isQihu() {
      return this.channel == 20200707006;
    },
    isQihu2() {
      return this.channel == 2887211655;
    },
    isMNBK() {
      return this.channel == "02067182521";
    },
  },
  created() {
    this.getAppRwmDownload();
  },
  methods: {
    downLoad() {
      this.$emit("download");
    },
    getAppRwmDownload() {
      qrcode().then((res) => {
        this.rwmImg = "data:image/jpg;base64," + res.data.qr_code;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes scan_animate {
  0% {
    -moz-transform: translate(0, -100%);
    -webkit-transform: translate(0, -100%);
    -o-transform: translate(0, -100%);
    -ms-transform: translate(0, -100%);
    transform: translate(0, -100%);
  }
  100% {
    -moz-transform: translate(0%, 134px);
    -webkit-transform: translate(0%, 134px);
    -o-transform: translate(0%, 134px);
    -ms-transform: translate(0%, 134px);
    transform: translate(0%, 134px);
  }
}
.pcDown {
  position: fixed;
  right: -2px;
  bottom: 248px;
  cursor: pointer;
  width: 154px;
  height: 54px;
  background: url("../../assets/images/bd01/pc_home.png") no-repeat;
  background-size: 100% 100%;
  z-index: 99;
  &:hover {
    background: url("../../assets/images/bd01/pc_home_h.png") no-repeat;
    background-size: 100% 100%;
  }
}
.phoneDown {
  position: fixed;
  right: -2px;
  bottom: 42px;
  width: 154px;
  height: 198px;
  background: url("../../assets/images/bd01/phoneDown_home.png") no-repeat;
  background-size: 100% 100%;
  z-index: 99;
  .rwm {
    width: 132px;
    position: absolute;
    right: 10px;
    top: 52px;
    border-radius: 12px;
  }
  .scanCover {
    width: 132px;
    height: 132px;
    position: absolute;
    right: 10px;
    top: 52px;
    border-radius: 12px;
    overflow: hidden;
    .scan_cover {
      position: absolute;
      left: 0;
      top: 0;
      width: 136px;
      height: 35px;
      background: url("../../assets/images/bd01/sm.png") no-repeat;
      background-size: 100% 100%;
      animation: scan_animate 2s linear infinite;
    }
  }
}
</style>
